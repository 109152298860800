import React from 'react';
import { SectionComponent } from '../../Section';

import Hand from '../../../../images/home-sections/s5-hand.jpg';

const Section5: SectionComponent = (
    { content }
) => {
    return (
        <>
            <div>
                <img src={Hand} loading="lazy" />
            </div>
            <p>{content.tghpworldsfairHomeSection5Title}</p>
        </>
    );
}

export default Section5;