import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useScroll } from 'framer-motion';
import { Media } from 'gatsby-plugin-fresnel';
import { useScreenOrientation, useMediaQuery } from '@react-hookz/web/esnext';
import Layout from '../components/Layout';
import Section, { SectionComponent } from '../components/Home/Section';
import Title from '../components/Home/Title';
import {
    Section1 as Section1Desktop,
    Section2 as Section2Desktop,
    Section3 as Section3Desktop,
    Section4 as Section4Desktop,
    Section5 as Section5Desktop,
    SectionFinal as SectionFinalDesktop
} from '../components/Home/Section/Desktop';
import {
    Section1 as Section1Mobile,
    Section2 as Section2Mobile,
    Section3 as Section3Mobile,
    Section4 as Section4Mobile,
    Section5 as Section5Mobile,
    SectionFinal as SectionFinalMobile
} from '../components/Home/Section/Mobile';
import NotFoundPage from '../pages/404';
import { WorldsFairHead } from '../components/Head';
import classNames from 'classnames/bind';

type HomeSectionList = [SectionComponent, string?][];
type WriteableNonNullable<T> = { [P in keyof T]: NonNullable<T[P]> };

const Home = ({ data: { home } }: PageProps<Queries.HomeTemplateQuery>) => {
    if (!home) {
        return <NotFoundPage />;
    }

    const orientation = useScreenOrientation();
    const isSmallHeight = useMediaQuery('(max-height: 500px)');
    const isLandscape = orientation === 'landscape' && isSmallHeight;

    const {
        tghpworldsfairHomeSection1Title,
        tghpworldsfairHomeSection1Subtitle,
        tghpworldsfairHomeSection2Title,
        tghpworldsfairHomeSection3Title,
        tghpworldsfairHomeSection4Title,
        tghpworldsfairHomeSection5Title,
        tghpworldsfairHomeSectionFinalTitle,
        tghpworldsfairHomeSectionFinalFormTitle,
    } = home;

    const content: WriteableNonNullable<Queries.HomeContentFragment> = {
        tghpworldsfairHomeSection1Title: tghpworldsfairHomeSection1Title || '',
        tghpworldsfairHomeSection1Subtitle: tghpworldsfairHomeSection1Subtitle || '',
        tghpworldsfairHomeSection2Title: tghpworldsfairHomeSection2Title || '',
        tghpworldsfairHomeSection3Title: tghpworldsfairHomeSection3Title || '',
        tghpworldsfairHomeSection4Title: tghpworldsfairHomeSection4Title || '',
        tghpworldsfairHomeSection5Title: tghpworldsfairHomeSection5Title || '',
        tghpworldsfairHomeSectionFinalTitle: tghpworldsfairHomeSectionFinalTitle || '',
        tghpworldsfairHomeSectionFinalFormTitle: tghpworldsfairHomeSectionFinalFormTitle || '',
    };

    for (const contentValue of Object.values(content)) {
        if (!contentValue) {
            return <NotFoundPage />;
        }
    }

    const { scrollYProgress } = useScroll();

    const desktopSections: HomeSectionList = [
        [Section1Desktop],
        [Section2Desktop],
        [Section3Desktop],
        [Section4Desktop],
        [Section5Desktop],
        [SectionFinalDesktop, 'final']
    ];

    const mobileSections: HomeSectionList = [
        [Section1Mobile],
        [Section2Mobile],
        [Section3Mobile],
        [Section4Mobile],
        [Section5Mobile],
        [SectionFinalMobile, 'final']
    ];

    const renderSections = (sections: HomeSectionList) => (
        sections.map((
                [HomeSection, sectionName],
                index
            ) =>
                <Section
                    key={index}
                    sectionNumber={!sectionName ? index + 1 : sectionName}
                    render={({ref, isReady}) =>
                        isReady && <HomeSection
                            content={content}
                            sectionRef={ref}
                            scrollYProgress={scrollYProgress}
                            isReady={isReady}
                        />
                    }
                />
        )
    );

    const desktopSwitch = 'l';

    return (
        <Layout location="home" chrome={"ONLY_HEADER"} seoData={home.seo}>
            <div className={classNames([
                'home-frame',
                isLandscape && `home-frame--orientation-landscape`
            ])}>
                {isLandscape &&
                    <div className="home-frame__notice">
                        Please change orientation to portrait
                    </div>
                }
                <div className="home-frame__border"/>
                <div className="home-frame__overlay"/>
            </div>
            <Media greaterThanOrEqual={desktopSwitch}>
                <Title
                    scrollYProgress={scrollYProgress}
                    content={content}
                    endScale={0.75}
                />
            </Media>
            <Media lessThan={desktopSwitch}>
                <Title
                    scrollYProgress={scrollYProgress}
                    content={content}
                    endScale={0.9}
                />
            </Media>
            <div className="home-content">
                <Media greaterThanOrEqual={desktopSwitch}>
                    {renderSections(desktopSections)}
                </Media>
                <Media lessThan={desktopSwitch}>
                    {renderSections(mobileSections)}
                </Media>
            </div>
        </Layout>
    );
}

export default Home;

export const query = graphql`
    query HomeTemplate ($id: Int!) {
        home: wpPage(databaseId: { eq: $id }) {
            title
            ...HomeContent
            ...SeoData
        }
    }
`

export const Head = () => <WorldsFairHead />