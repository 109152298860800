import React, { useLayoutEffect, useState, useRef } from 'react';
import { MotionValue } from 'framer-motion';

type SectionProps = {
    render: (renderArgs: {
        ref: React.MutableRefObject<HTMLDivElement|null>;
        isReady: boolean;
    }) => React.ReactNode;
    sectionNumber: number | string;
}

export type SectionComponentProps = {
    content: Queries.HomeContentFragment;
    sectionRef: React.MutableRefObject<HTMLDivElement|null>;
    scrollYProgress: MotionValue<number>;
    isReady: boolean;
}

export type SectionComponent = React.FC<SectionComponentProps>;

const Section = ({ render, sectionNumber }: SectionProps) => {
    const ref = useRef<HTMLDivElement|null>(null);
    const [isReady, setIsReady] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (ref.current) {
            setIsReady(true);
        }
    }, [ref]);

    return (
        <section className={`home-content__section home-content__section--${sectionNumber}`} ref={ref}>
            {render({ ref, isReady })}
        </section>
    );
}

export default Section;