import React  from 'react';
import { useTransform, motion } from 'framer-motion';
import useRefScrollProgress from '../../../../hooks/useRefScrollProgress';
import { SectionComponent } from '../../Section';
import { easeInOut } from '@popmotion/easing';

import Energy from '../../../../images/home-sections/s2-energy.png';

const Section2: SectionComponent = (
    { sectionRef, scrollYProgress, content }
) => {
    const [energyStart, energyEnd] = useRefScrollProgress(
        sectionRef
    );

    const energyOpacity = useTransform(
        scrollYProgress,
        [energyStart, energyEnd],
        [
            '0',
            '1',
        ],
        {
            ease: easeInOut
        }
    );

    const energyTranslateX = useTransform(
        scrollYProgress,
        [energyStart, energyEnd],
        [
            'translateX(-100%)',
            'translateX(0%)',
        ],
        {
            ease: easeInOut
        }
    );

    const energyPosition = useTransform(
        scrollYProgress,
        y => {
            if (y > energyEnd) {
                return 'static';
            }

            return '';
        }
    );

    const energyMarginBottom = useTransform(
        scrollYProgress,
        (y) => {
            if (y < energyEnd) {
                return 0;
            }

            return '';
        }
    );

    return (
        <>
            <motion.div
                style={{
                    opacity: energyOpacity,
                    position: energyPosition,
                    transform: energyTranslateX,
                    marginBottom: energyMarginBottom,
                }}
            >
                <img src={Energy} loading="lazy" />
            </motion.div>

            <p>{content.tghpworldsfairHomeSection2Title}</p>
        </>
    );
}

export default Section2;