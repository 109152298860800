import React  from 'react';
import { useTransform, motion } from 'framer-motion';
import useRefScrollProgress from '../../../../hooks/useRefScrollProgress';
import { SectionComponent } from '../../Section';
import { easeInOut } from '@popmotion/easing';

import Air1 from '../../../../images/home-sections/s4-air1.png';
import Air2 from '../../../../images/home-sections/s4-air2.png';
import Air3 from '../../../../images/home-sections/s4-air3.png';

const Section4: SectionComponent = (
    { sectionRef, scrollYProgress, content }
) => {
    const [allStart, allEnd] = useRefScrollProgress(
        sectionRef,
        {
            start: '-20%',
            end: '500%',
        }
    );

    const [allMidStart, allMidEnd] = useRefScrollProgress(
        sectionRef,
        {
            start: '0%',
            end: '100%',
        }
    );

    const allOpacity = useTransform(
        scrollYProgress,
        [allStart, allMidEnd, allEnd],
        [
            '0',
            '1',
            '0',
        ]
    );

    const air1TranslateX = useTransform(
        scrollYProgress,
        [allStart, allMidEnd, allEnd],
        [
            'translateY(-60vh) translateX(-50%)',
            'translateY(10vh) translateX(0%)',
            'translateY(30vh) translateX(40%)',
        ]
    );

    const air2TranslateX = useTransform(
        scrollYProgress,
        [allStart, allMidEnd, allEnd],
        [
            'translateY(-60vh) translateX(-80%)',
            'translateY(10vh) translateX(0%)',
            'translateY(30vh) translateX(50%)',
        ]
    );

    const air3TranslateX = useTransform(
        scrollYProgress,
        [allStart, allMidEnd, allEnd],
        [
            'translateY(-60vh) translateX(-110%)',
            'translateY(10vh) translateX(0%)',
            'translateY(30vh) translateX(60%)',
        ]
    );

    return (
        <>
            <motion.div
                style={{
                    opacity: allOpacity,
                    transform: air1TranslateX,
                }}
            >
                <img src={Air1} loading="lazy" />
            </motion.div>
            <motion.div
                style={{
                    opacity: allOpacity,
                    transform: air2TranslateX,
                }}
            >
                <img src={Air2} loading="lazy" />
            </motion.div>
            <motion.div
                style={{
                    opacity: allOpacity,
                    transform: air3TranslateX,
                }}
            >
                <img src={Air3} loading="lazy" />
            </motion.div>

            <p>{content.tghpworldsfairHomeSection4Title}</p>
        </>
    );
}

export default Section4;