import React  from 'react';
import { useTransform, motion } from 'framer-motion';
import useRefScrollProgress from '../../../../hooks/useRefScrollProgress';
import { SectionComponent } from '../../Section';
import { easeInOut, easeOut } from '@popmotion/easing';

import Brain from '../../../../images/home-sections/s3-brain.jpg';

const Section3: SectionComponent = (
    { sectionRef, scrollYProgress, content }
) => {
    const [brainStart, brainEnd] = useRefScrollProgress(
        sectionRef
    );

    const brainOpacity = useTransform(
        scrollYProgress,
        [brainStart, brainEnd],
        [
            '0',
            '1',
        ],
        {
            ease: easeInOut
        }
    );

    const brainTranslateY = useTransform(
        scrollYProgress,
        [brainStart, brainEnd],
        [
            'translateY(-110%)',
            'translateY(0%)',
        ],
        {
            ease: easeOut
        }
    );

    const innerBrainScale = useTransform(
        scrollYProgress,
        [brainStart, brainEnd],
        [
            'scale(0.85)',
            'scale(1)',
        ],
        {
            ease: easeInOut
        }
    );

    return (
        <>
            <motion.div
                style={{
                    opacity: brainOpacity,
                    transform: brainTranslateY,
                }}
            >
                <div>
                    <motion.img
                        src={Brain}
                        style={{
                            transform: innerBrainScale,
                        }}
                        loading="lazy"
                    />
                </div>
            </motion.div>

            <p>{content.tghpworldsfairHomeSection3Title}</p>
        </>
    );
}

export default Section3;