import React from 'react';
import { useTransform, motion } from 'framer-motion';
import { easeInOut } from '@popmotion/easing';
import { SectionComponent } from '../../Section';
import useRefScrollProgress from '../../../../hooks/useRefScrollProgress';

import DownArrow from '../../../../images/ui/chevron-home-down.inline.svg';
import City from '../../../../images/home-sections/s1-city.jpg';

const Section1: SectionComponent = (
    { sectionRef, scrollYProgress, content }
) => {
    const [introFadeStart, introFadeEnd] = useRefScrollProgress(
        sectionRef,
        {
            end: '50%',
        }
    );

    const [introFadeStartLater, introFadeEndLater] = useRefScrollProgress(
        sectionRef,
        {
            start: '10%',
            end: '90%',
        }
    );

    const textOpacity = useTransform(
        scrollYProgress,
        [introFadeStart, introFadeEnd],
        [
            '1',
            '0',
        ],
        {
            ease: easeInOut
        }
    );

    const textOpacityLater = useTransform(
        scrollYProgress,
        [introFadeStartLater, introFadeEndLater],
        [
            '1',
            '0',
        ],
        {
            ease: easeInOut
        }
    );

    return (
        <>
            <p>
                {content.tghpworldsfairHomeSection1Title}
            </p>
            <motion.p
                style={{
                    opacity: textOpacity,
                }}
            >
                {content.tghpworldsfairHomeSection1Subtitle}
            </motion.p>
            <motion.button
                style={{
                    opacity: textOpacityLater,
                }}
            >
                <DownArrow />
            </motion.button>
            <p><img src={City} loading="eager" /></p>
        </>
    );
}

export default Section1;