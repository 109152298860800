import React from 'react';
import { useState, useLayoutEffect } from 'react';

type OffsetPropDefiniton = {
    start?: number | string
    end?: number | string
}

type OffsetCalculatedDefiniton = {
    start: number
    end: number
}

type OffsetKeys = keyof OffsetPropDefiniton;

export default function useRefScrollProgress(
    ref: React.MutableRefObject<null | HTMLDivElement>,
    offset?: OffsetPropDefiniton
): [number, number] {
    const [start, setStart] = useState<number | null>(null);
    const [end, setEnd] = useState<number | null>(null);

    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }

        let calculatedOffsets: OffsetCalculatedDefiniton = {
            start: 0,
            end: 0,
        };

        if (offset) {
            const offsetKeys: OffsetKeys[] = ['start', 'end'];

            for (const offsetKey of (offsetKeys as OffsetKeys[])) {
                if (offset[offsetKey]) {
                    const offsetValue = offset[offsetKey];

                    if (typeof offsetValue === 'string') {
                        if (offsetValue.match('/%$/')) {
                            const offsetDecimal = parseFloat(offsetValue.replace('%', '')) / 100;
                            calculatedOffsets[offsetKey] = window.innerHeight * offsetDecimal;
                        } else {
                            calculatedOffsets[offsetKey] = parseFloat(offsetValue);
                        }
                    } else if (typeof offsetValue === 'number') {
                        calculatedOffsets[offsetKey] = offsetValue;
                    }
                }
            }
        }

        const rect = ref.current?.getBoundingClientRect();
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const offsetTop = rect.top + scrollTop - window.innerHeight;

        const documentHeight = document.body.clientHeight - window.innerHeight;

        setStart(Math.max(0, ((offsetTop + calculatedOffsets.start) / documentHeight)));
        setEnd((offsetTop + calculatedOffsets.end + rect.height) / documentHeight);
    }, [ref]);

    return [start || 0, end || 0];
}