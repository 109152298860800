import React from 'react';
import { useTransform, motion } from 'framer-motion';
import { easeInOut, easeOut } from '@popmotion/easing';
import { SectionComponent, SectionComponentProps } from '../../Section';
import useRefScrollProgress from '../../../../hooks/useRefScrollProgress';

import Hand from '../../../../images/home-sections/s5-hand.jpg';

const Section5: SectionComponent = (
    { sectionRef, scrollYProgress, content }
) => {
    const [handStart, handEnd] = useRefScrollProgress(
        sectionRef
    );

    const handOpacity = useTransform(
        scrollYProgress,
        [handStart, handEnd],
        [
            '0',
            '1',
        ],
        {
            ease: easeInOut
        }
    );

    const handTranslateX = useTransform(
        scrollYProgress,
        [handStart, handEnd],
        [
            'translateX(-90%)',
            'translateX(0%)',
        ],
        {
            ease: easeOut
        }
    );

    const handPosition = useTransform(
        scrollYProgress,
        y => {
            if (y > handEnd) {
                return 'static';
            }

            return '';
        }
    );

    const handMarginTop = useTransform(
        scrollYProgress,
        (y) => {
            if (y > handEnd) {
                return 0;
            }

            return '';
        }
    );

    return (
        <>
            <motion.div
                style={{
                    opacity: handOpacity,
                    transform: handTranslateX,
                    position: handPosition,
                    marginTop: handMarginTop,
                }}
            >
                <img src={Hand} loading="lazy" />
            </motion.div>
            <p>{content.tghpworldsfairHomeSection5Title}</p>
        </>
    );
}

export default Section5;