import React, { useLayoutEffect, useRef, useState } from 'react';
import { MotionValue, useTransform, motion } from 'framer-motion';

export type TitleComponentProps = {
    scrollYProgress: MotionValue<number>;
    endScale: number;
    content: Queries.HomeContentFragment;
}

const Title = ({ scrollYProgress, endScale, content }: TitleComponentProps) => {
    const endsAtPercent = 7;
    const titleRef = useRef<HTMLDivElement>(null);
    const [isTitleReady, setIsTitleReady] = useState<boolean>(false);

    useLayoutEffect(() => {
        if (titleRef.current) {
            setIsTitleReady(true);
        }
    }, [titleRef])

    const calculateValue = (from:number, to:number, y:number): number => {
        const factor = (100 / endsAtPercent);
        const difference = (to - from);
        return from + (difference * (Math.min(1, y * factor)));
    }

    const titleMarginTop = useTransform(
        scrollYProgress,
        y => {
            if (titleRef.current !== null) {
                const from = parseInt(getComputedStyle(titleRef.current).getPropertyValue('--margin-top-from'));
                const to = parseInt(getComputedStyle(titleRef.current).getPropertyValue('--margin-top-to'));
                return calculateValue(from, to, y);
            }

            return 0;
        }
    );

    const titleTransform = useTransform(
        scrollYProgress,
        y => `translateX(-50%) scale(${calculateValue(1, endScale, y)}`
    );

    const titleObscureWidth = useTransform(
        scrollYProgress,
        y => `${calculateValue(0, 350, y)}px`
    );

    return (
        <>
            <motion.div
                className="home-title"
                style={{
                    marginTop: titleMarginTop,
                    transform: titleTransform,
                }}
                ref={titleRef}
            >
                {isTitleReady && content.tghpworldsfairHomeSection1Title}
            </motion.div>
            <motion.div
                className="home-title-obscure"
                style={{
                    width: titleObscureWidth,
                }}
            />
        </>
    )
};

export default Title;