import React from 'react';
import { SectionComponent } from '../Section';
import Signup from '../../Signup';

const SectionFinal: SectionComponent = ({ content }) => {
    return (
        <>
            <p>{content.tghpworldsfairHomeSectionFinalTitle}</p>
            <p>
                <video autoPlay={true} muted={true} controls={false} loop={true} playsInline={true}>
                    <source src="/images/wfc-spin.mp4" type="video/mp4" />
                </video>
            </p>
            <p>{content.tghpworldsfairHomeSectionFinalFormTitle}</p>
            <Signup modifierClass="footer" sources={['Home Final Slide']} />
        </>
    );
}

export default SectionFinal;